<template>
  <v-dialog v-model="dialog" max-width="80%">
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(value, key) in editedItem"
                :key="key"
              >
                <v-text-field
                  :label="key"
                  readonly
                  :value="isCommon(key) ? displayCommon(value) : value"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">{{
          $t("plan.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RegNoDetail",
  props: {
    value: Boolean,
    item: Object,
  },
  data() {
    return {
      editedItem: {},
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    title() {
      const length = this.item?.["登記次序"]?.length ?? 0;
      const key = length > 4 ? "view_c_data" : "view_o_data";
      return this.$t(`plan.${key}`);
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.editedItem = JSON.parse(JSON.stringify(this.item));
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      this.$emit("save", this.editedItem);
      this.close();
    },
    isCommon(item) {
      return ["共同擔保建號o", "共同擔保地號o"].includes(item);
    },
    displayCommon(item) {
      if (item == null || item == "" || item == undefined) return null;
      // replace _x000D_ with ; for item
      item = item.replace(/_x000D_/g, ";");
      return item;
    },
  },
};
</script>
