<template>
  <v-container fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t(prop_title)"
      class="px-5 py-3"
    >
    <!-- show final dialog -->
    <v-dialog v-model="final_dialog" max-width="400px" @click:outside="onCloseFinalDialog">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ message.title }}
        </v-card-title>
        <v-card-text>
          {{ message.text }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onCloseFinalDialog"> OK </v-btn>
          <!-- export urban excel -->
          <v-btn color="primary"  @click="exportTranscribe"> 匯出繕打(Excel) </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <!-- import to urban dialog -->
      <v-dialog v-model="import_dialog" max-width="50%" persistent>
        <v-card>
          <v-card-title>請選擇匯入都更會</v-card-title>
          <v-card-text>
            <v-radio-group v-model="selectedOption">
              <v-row no-gutters>
                <v-radio label="新增更新會" :value="1"></v-radio>
                <v-col cols="8" class="mx-4">
                  <!-- text-field -->
                  <v-text-field
                    v-model="new_urban_name"
                    :label="$t('plan.newUrbanName')"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-radio label="匯入現有" :value="2"></v-radio>
                <v-col cols="8" class="mx-4" v-if="selectedOption === 2">
                  <v-select
                    v-model="choose_urban"
                    :items="urbans"
                    item-text="name"
                    item-value="id"
                    :label="$t('issue.chooseUrban')"
                    hide-details
                    persistent-hint
                    return-object
                    filled
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="importToUrban"
              >匯入</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end of import to urban dialog -->
      <dialog-message
        :dialog_message="dialog_message"
        :message="message"
      ></dialog-message> 
      <dialog-progress
        :dialog_message="isParsing || isImporting"
        :message="progress_message"
        @close="closeProgress"
      ></dialog-progress>
      <!-- main -->
      <v-card-title>
        <v-file-input
          v-model="selectedFiles"
          label="選擇PDF文件"
          multiple
          accept=".pdf,.mdata"
          :show-size="true"
          class="mx-2"
          :truncate-length="100"
          @change="handleFileChange"
        ></v-file-input>

        <v-btn
          color="primary"
          @click="uploadFiles"
          class="mb-6"
          :disabled="!selectedFiles.length"
        >
          上傳文件
        </v-btn>
        <!-- add reset btn -->
        <v-btn color="default" @click="reset()" class="mb-6"> 重置 </v-btn>
      </v-card-title>
      <owner-charge-component
        v-if="isParsed"
        :owner_count="owner_count"
        :charge="charge"
        :user_balance="user_balance"
        :pdf_files="pdf_files"
        @onPay="onPay"
      ></owner-charge-component>
      <v-card-title v-if="isCompleted">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          dark
          class="mx-2"
          @click="showImportUrbanDialog"
        >
          <v-icon left>mdi-pencil-plus</v-icon>
          {{ $t("common.import") }}
        </v-btn>
      </v-card-title>
      <v-card-title v-if="isProcessing">
        <!-- add label OCR -->
        <v-label>OCR</v-label>
        <v-progress-linear :value="uploadProgress" height="25" striped>
          <strong>{{ Math.ceil(uploadProgress) }}%</strong>
        </v-progress-linear>
      </v-card-title>
      <transcript
        v-if="isShowTranscript"
        :propTranscripts="transcripts"
        ref="transcript"
      ></transcript>
    </base-material-card>
  </v-container>
</template>

<script>
import Transcript from "@/views/dashboard/pages/Transcript";
import OwnerChargeComponent from "@/views/dashboard/pages/OwnerChargeComponent.vue";
import UrbanMixin from "../common/UrbanMixin.vue";
import { mapState } from "vuex";
import { SocketEventType } from "@/definition";
import { ImportState } from "@/definition";

const TaskStatus = {
  Init: 0,
  Running: 1,
  Success: 2,
  Failed: 3,
};

export default {
  props: {
    prop_title: {
      type: String,
      default: "title.ImportTranscript",
    },
  },
  mixins: [UrbanMixin],
  data() {
    return {
      task_id: null,
      isPolling: false,
      selectedFiles: [],
      uploadProgress: 0,
      progress_message: {
        title: this.$t("plan.import-pdf"),
        text: "謄本解析中...",
      },
      transcripts: [],
      regnos_count: 0,
      processed: 0,
      last_parsed_filenames: null,
      SocketEventType: SocketEventType,
      // import urban dialog
      selectedOption: 1,
      import_dialog: false,
      new_urban_name: "",
      urban_token: null,
      urban_id: null,
      // charge
      ImportState: ImportState,
      status: ImportState.Init,
      owner_count: 0,
      charge: 0,
      pdf_files: [],
      user_balance: 0,
      dialog_message: false,
      message: {
        title: "",
        text: "",
      },
      final_dialog: false,
    };
  },
  computed: {
    ...mapState(["token", "decimal_places"]),
    isParsing() {
      return this.status === ImportState.Parsing;
    },
    isPaid() {
      return this.status === ImportState.Paid;
    },
    isProcessing() {
      return this.status === ImportState.Processing;
    },
    isParsed() {
      return this.status === ImportState.Parsed;
    },
    isCompleted() {
      return this.status === ImportState.Completed;
    },
    isShowTranscript() {
      return this.status >= ImportState.Paid;
    },
    isImporting() {
      return this.status === ImportState.Importing;
    },
  },
  components: {
    Transcript,
    OwnerChargeComponent,
  },
  mounted() {
    this.$options.sockets.onmessage = this.receiveSocketData;

    this.getUrbanRenewal();
  },
  beforeDestroy() {
    delete this.$options.sockets.onmessage;
  },
  methods: {
    handleFileChange(files) {
      this.selectedFiles = files;
    },
    async uploadFiles() {
      if (!this.selectedFiles.length) return;

      this.status = ImportState.Parsing;
      this.uploadProgress = 0;
      this.reset_urban_info();
      const formData = new FormData();
      for (let i = 0; i < this.selectedFiles.length; i++) {
        formData.append("files", this.selectedFiles[i]);
      }
      try {
        await this.parseFile(formData);
        this.$emit("upload-complete");
      } catch (error) {
        console.error("Upload failed:", error);
        this.status = ImportState.Failed;
      } finally {
      }
    },
    showImportUrbanDialog() {
      this.import_dialog = true;
    },
    importToUrban() {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/import_to_urban/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      let currentRegistration = this.$refs.transcript
        ? this.$refs.transcript.currentRegistration
        : null;
      if (currentRegistration == null) {
        return;
      }
      this.progress_message = {
        title: this.$t("plan.import-pdf"),
        text: "更新會匯入...",
      };
      this.status = ImportState.Importing;
      let lbkey = currentRegistration.lbkey;
      let data = {
        urban_id:
          this.choose_urban && this.selectedOption === 2
            ? this.choose_urban.id
            : null,
        lbkey: lbkey,
        filename: this.last_parsed_filenames,
        urban_name: this.new_urban_name,
        urban_token: this.urban_token,
      };
      let msg = "匯入完成至:" + this.new_urban_name;
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.urban_id = response.data.urban_id;
          currentObj.status = ImportState.ImportDone;
          currentObj.importDone(msg);
        })
        .catch(function (error) {
          console.log(error);
          currentObj.status = ImportState.Failed;
          this.showMessage("更新會匯入失敗");
        })
        .finally(() => {
          // set timeout to close the dialog after 300 ms
          this.import_dialog = false;
        });
    },
    importDone(msg) {
      console.log("importDone", this.urban_id);
      setTimeout(() => {
        // get the urban
        this.getUrbanRenewal();
        this.showFinalDialog(msg);
      }, 300);
    },
    showFinalDialog(msg) {
      this.message = {
        title: this.$t("plan.import-pdf"),
        text: msg,
      };
      this.final_dialog = true;
    },
    showMessage(msg) {
      this.message = {
        title: this.$t("plan.import-pdf"),
        text: msg,
      };
      this.dialog_message = true;
    },
    parseFile(formData) {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/parse/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      this.reset_transcript();
      this.progress_message = {
        title: this.$t("plan.import-pdf"),
        text: "謄本解析中...",
      };

      this.axios
        .post(url, formData, config)
        .then(async function (response) {
          currentObj.loading = false;
          let data = response.data;
          console.log("parseFile", data);
          currentObj.task_id = data.task_id;
          await currentObj.get_task_status(); // Start polling for task status
        })
        .catch(function (error) {
          console.log(error);
          currentObj.status = ImportState.Failed;
          currentObj.showMessage("解析失敗 請稍後再試");
        })
        .finally(() => {});
    },

    async get_task_status() {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/task_status/`;
      let retryCount = 0;
      const maxRetries = 300;
      const retryInterval = 4000; // 4 seconds
      this.isPolling = true;

      const checkStatus = async () => {
        if (!this.isPolling) {
          return true; // Stop polling if dialog is closed
        }

        try {
          const response = await this.axios.get(url, {
            params: { task_id: this.task_id },
            headers: {
              Authorization: "Token " + this.token,
            },
          });

          const { status, data, error_message } = response.data;
          console.log("get_task_status", { status, data, error_message });

          if (status === TaskStatus.Success) {
            this.setData(data);
            return true;
          } else if (status === TaskStatus.Failed) {
            console.error("Task failed:", error_message);
            this.status = ImportState.Failed;
            this.showMessage("解析失敗 請稍後再試");
            return true;
          } else if (retryCount < maxRetries) {
            retryCount++;
            await new Promise((resolve) => setTimeout(resolve, retryInterval));
            return false;
          } else {
            this.status = ImportState.Failed;
            this.showMessage("解析超時 請稍後再試");
            return true;
          }
        } catch (error) {
          console.error("Error checking task status:", error);
          this.status = ImportState.Failed;
          this.showMessage("解析失敗 請稍後再試");
          return true;
        }
      };

      while (!(await checkStatus())) {
        // Continue polling until we get a final status or hit max retries
      }
      this.isPolling = false;
    },
    // setSelectedRegistration() {
    //   this.$nextTick(() => {
    //     if (this.$refs.transcript != null) {
    //       // 確保在 DOM 更新後能正確獲取 transcript 引用
    //       if (this.transcripts.length > 0) {
    //         let lbkey = this.transcripts[0].lbkey;
    //         this.$refs.transcript.setSelectedRegistration(lbkey);
    //       }
    //     } else {
    //       console.log("Not set it");
    //     }
    //   });
    // },

    setData(data) {
      let results = data.results;
      this.transcripts = results;
      let filename = data.filename;
      for (let transcript of results) {
        // loop the o_data and c_data if 住址 not in item then this.regnos_count += 1
        for (let item of transcript.o_data) {
          if (!item.hasOwnProperty("住址")) {
            this.regnos_count += 1;
          }
        }
        for (let item of transcript.c_data) {
          if (!item.hasOwnProperty("住址")) {
            this.regnos_count += 1;
          }
        }
      }
      this.owner_count = data.owner_count;
      this.charge = data.charge;
      this.pdf_files = data.pdf_files;
      this.user_balance = data.user_balance;
      console.log("owner count is", this.owner_count, " charge:", this.charge);
      console.log("send event to server", filename);

      this.last_parsed_filenames = filename;
      this.new_urban_name = data.urban_name;
      this.status = ImportState.Parsed;
      this.urban_token = data.urban_token;
      // test
      // this.onPaid();
    },
    reset_transcript() {
      this.regnos_count = 0;
      this.processed = 0;
      // if ref.transcript is not null, call reset method
      if (this.$refs.transcript) {
        this.$refs.transcript.reset();
      }
    },
    reset() {
      this.isPolling = false; // Stop polling when reset
      this.selectedFiles = [];
      this.transcripts = [];
      this.last_parsed_filenames = null;
      this.status = ImportState.Init;
      this.uploadProgress = 0;
      this.selectedOption = 1;
      this.import_dialog = false;
      this.owner_count = 0;
      this.charge = 0;
      this.pdf_files = [];
      this.user_balance = 0;
      this.reset_urban_info();
      this.reset_transcript();
    },
    reset_urban_info() {
      this.new_urban_name = "";
      this.urban_id = null;
      this.urban_token = null;
    },
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      if (info.action == SocketEventType.TranscriptParseEvent) {
        let data = info.data;
        this.$refs.transcript.updateOwnerData(data);
        this.processed += 1;
        let progress = (this.processed / this.regnos_count) * 100;

        progress = progress > 100 ? 100 : progress;
        this.uploadProgress = progress;
      } else if (info.action == SocketEventType.TranscriptParseEnd) {
        this.status = ImportState.Completed;
        this.import_dialog = true;

      } else if (info.action == SocketEventType.TranscriptParseError) {
        this.message = {
          title: this.$t("plan.import-pdf"),
          text: info.data.error_message,
        };
        this.dialog_message = true;
        this.status = ImportState.Failed;
      }
    },
    retrySendObj(data, maxRetries = 3, delay = 10000) {
      let retries = 0;
      this.status = ImportState.Processing;
      const attempt = () => {
        if (this.$socket.readyState === WebSocket.OPEN) {
          this.$socket.sendObj(data);
          console.log("Message sent successfully");
        } else if (retries < maxRetries) {
          retries++;
          console.log(
            `WebSocket not ready. Retrying in ${
              delay / 1000
            } seconds... (Attempt ${retries}/${maxRetries})`
          );
          setTimeout(attempt, delay);
        } else {
          console.error(`Failed to send after ${maxRetries} attempts`);
        }
      };

      attempt();
    },
    onPay() {
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/balance/withdraw/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      let filenames = this.selectedFiles.map((file) => file.name).join(",");

      let data = {
        balance: this.charge,
        description: filenames,
        task_id: this.task_id,
      };
      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response);
          currentObj.onPaid();
        })
        .catch(function (error) {
          console.log(error);
          // get the error message
          if (error.response.data) {
            let message = error.response.data;
            console.log("error message", message.error);
            if (message.error == "Insufficient balance") {
              currentObj.showMessage("餘額不足");
            } else {
              console.log("error message", message.error);
              currentObj.showMessage(message.error);
            }
          } else {
            currentObj.showMessage("付款失敗");
          }
        });
    },
    onPaid() {
      console.log("pay it.", this.last_parsed_filenames);
      this.status = ImportState.Paid;
      //this.setSelectedRegistration();
      this.retrySendObj({
        action: SocketEventType.TranscriptParseStart,
        filename: this.last_parsed_filenames,
        urban_token: this.urban_token,
        task_id: this.task_id,
      });
    },
    closeProgress() {
      this.isPolling = false; // Stop polling when dialog closes
    },
    close() {
      this.dialog_message = false;
    },
    // export urban excel
    exportTranscribe() {
      this.onExporting();
      const url = `${process.env.VUE_APP_SERVER_URL}/plan/transcribe/export/`;
      const currentObj = this;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        responseType: "blob",
      };
      const data = {
        urban_id: this.urban_id,
        decimal_places: this.decimal_places
      };
      let filename = `${this.new_urban_name}_繕打.xlsx`;
      this.axios
        .post(url, data, config)
        .then(function (response) {
          currentObj.forceFileDownload(response, filename);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.onExportDone();
        });
    },
    onExporting() {
      this.progress_message = {
        title: this.$t("plan.transcribe"),
        text: "匯出資料中...",
      };
      this.dialog_message = false;
      this.final_dialog = false;
      this.progress_dialog = true;
    },
    onExportDone() {
      this.progress_dialog = false;
    },
    forceFileDownload(response, filename) {
      console.log("forceFileDownload:", filename);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
    onCloseFinalDialog() {
      this.final_dialog = false;
    },
  },
};
</script>

<style scoped>
.v-file-input {
  margin-bottom: 20px;
}
</style>
